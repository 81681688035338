<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { TheMask } from "vue-the-mask";
import { debounce } from "throttle-debounce";
import TransferAccountInfo from "@/components/transfers/TransferAccountInfo.vue";

export default {
  components: {
    TheMask,
    TransferAccountInfo,
  },

  props: {
    label: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      accountId: "",

      searchStatus: "",
    };
  },

  computed: {
    ...mapState({
      recipientInfo: (state) => state.transfer.recipientInfo,
    }),
  },

  methods: {
    ...mapMutations({
      setRecipientInfo: "transfer/SET_RECIPIENT_INFO",
      setTransferAccountTo: "transfer/SET_TRANSFER_ACCOUNT_TO",
    }),

    ...mapActions({
      loadRecipientInfo: "transfer/loadRecipientInfo",
    }),

    searchAccount: debounce(1000, function () {
      this.loadRecipientInfo(this.accountId)
        .then(() => {
          this.setTransferAccountTo(this.recipientInfo);
          this.searchStatus = "success";
        })
        .catch(() => {
          this.searchStatus = "error";

          setTimeout(() => {
            this.searchStatus = "";
          }, 5000);
        });
    }),
  },

  created() {
    this.setRecipientInfo(null);
  },
};
</script>

<template>
  <div class="transfer-search-account">
    <p v-if="label" class="transfer-search-account__label">{{ label }}</p>

    <div class="transfer-search-account__wrapper">
      <div class="transfer-search-account__search">
        <the-mask
          v-model="accountId"
          mask="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
          masked
          type="text"
          @input="searchAccount"
        />
      </div>

      <div v-if="recipientInfo" class="transfer-search-account__user">
        <transfer-account-info :account="recipientInfo" />
      </div>

      <div
        v-if="searchStatus === 'error'"
        class="transfer-search-account__error"
      >
        Пользователь не найден
      </div>

      <p
        v-if="!recipientInfo && searchStatus !== 'error'"
        class="transfer-search-account__text"
      >
        Вставьте номер дистрибьюторского или клиентского счета другого человека
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.transfer-search-account {
  &__label {
    @include text-2;
    color: $dark-sixth;
    margin-bottom: $space-s;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__search {
    max-width: 550px;

    > input {
      width: 320px;
      background: $background-grey;
      border: 1px solid $light-fifth;
      border-radius: 8px;
      color: $dark-primary;
      padding: 13px $space-m;
      outline: none;
      @include text-2;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__user {
    margin: 0 0 0 $space-l;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
      margin: $space-s 0 0 0;
    }
  }

  &__error {
    color: $red;
    margin: 0 0 0 $space-l;
    @include text-2;

    @media (max-width: 768px) {
      width: 100%;
      margin: $space-s 0 0 0;
    }
  }

  &__text {
    color: $dark-sixth;
    margin: 0 0 0 $space-l;
    @include caption-1;
    max-width: 270px;

    @media (max-width: 768px) {
      margin: $space-s 0 0 0;
    }
  }
}
</style>
