<script>
import formatAccountType from "@/package/helpers/format-account-type";

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  computed: {
    accountType() {
      return formatAccountType(this.account);
    },
  },
};
</script>

<template>
  <div class="transfer-account-info">
    <div class="transfer-account-info__image">
      <img v-if="account.avatar" :src="account.avatar" alt="" />

      <img v-else src="../../assets/images/user_no_img.svg" alt="" />
    </div>
    <div class="transfer-account-info__wrapper">
      <div class="transfer-account-info__name">
        {{ account.name }}
      </div>
      <div class="transfer-account-info__account-type">
        {{ accountType }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.transfer-account-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__image {
    min-width: 32px;
    width: 32px;
    height: 32px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 $space-s 0 0;

    > img {
      width: 100%;
    }
  }

  &__name {
    color: $dark-primary;
    @include text-2;
    margin-bottom: $space-xxs;
  }

  &__account-type {
    color: $dark-sixth;
    @include caption-1;
  }
}
</style>
