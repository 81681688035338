<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { throttle } from "throttle-debounce";
import TransferAmountInput from "@/components/transfers/TransferAmountInput";
import TransferSelector from "@/components/transfers/TransferSelector";
import TransferSuccess from "@/components/transfers/TransferSuccess";
import MainTitleBack from "@/components/helpers/MainTitleBack";
import TransferSearchAccount from "@/components/transfers/TransferSearchAccount.vue";
import MainButton from "@/components/helpers/MainButton";
import MainCheckbox from "@/components/helpers/MainCheckbox";

export default {
  components: {
    MainCheckbox,
    MainTitleBack,
    TransferAmountInput,
    TransferSuccess,
    TransferSelector,
    TransferSearchAccount,
    MainButton,
  },

  data() {
    return {
      amount: "",
      loading: false,
      transferStatus: "",

      transferAmountError: false,
      transferAmountErrorMessage: "",

      isAccepted: false,
    };
  },

  watch: {
    amount() {
      if (this.amount && this.amount < 100) {
        this.transferAmountError = true;
        this.transferAmountErrorMessage = "Минимальная сумма - 100 RC";
      } else if (
        this.transferAccountFrom &&
        this.transferAccountFrom.summary_active < this.amount
      ) {
        this.transferAmountError = true;
        this.transferAmountErrorMessage = "Недостаточно средств";
      } else if (this.amount % 100 !== 0) {
        this.transferAmountError = true;
        this.transferAmountErrorMessage = "Сумма должна быть кратна 100";
      } else {
        this.transferAmountError = false;
        this.transferAmountErrorMessage = "";
      }
    },
  },

  computed: {
    ...mapState({
      transferAccountFrom: (state) => state.transfer.transferAccountFrom,
      transferAccountTo: (state) => state.transfer.transferAccountTo,
    }),

    ...mapGetters({
      getUserAccounts: "auth/getUserAccounts",
    }),

    transferButtonText() {
      return this.loading ? "Переводим..." : "Перевести";
    },
  },

  methods: {
    ...mapMutations({
      setTransferAccountFrom: "transfer/SET_TRANSFER_ACCOUNT_FROM",
      setTransferAccountTo: "transfer/SET_TRANSFER_ACCOUNT_TO",
    }),

    ...mapActions({
      storeTransfer: "transfer/storeTransfer",
      checkUser: "auth/checkUser",
    }),

    storeTransferRequest: throttle(5000, function () {
      this.loading = true;
      const data = {
        accountFromId: this.transferAccountFrom.id,
        accountToId: this.transferAccountTo.id,
        amount: this.amount,
      };

      this.storeTransfer(data).then((response) => {
        this.loading = false;

        if (response.data.message === "Трансфер совершен") {
          this.transferStatus = "success";
          this.checkUser();
        } else {
          this.transferStatus = "error";

          setTimeout(() => {
            this.transferStatus = "";
          }, 3000);
        }
      });
    }),
  },

  mounted() {
    this.setTransferAccountFrom(null);
    this.setTransferAccountTo(null);
  },
};
</script>

<template>
  <div class="transfer-to-client">
    <div v-if="transferStatus !== 'success'">
      <MainTitleBack title="Другому человеку" href="Transfers" />

      <div class="transfer-to-client__block">
        <div class="transfer-to-client__selector">
          <TransferSelector
            label="Счет списания"
            :selected-account="transferAccountFrom"
            :accounts="getUserAccounts"
            @selectTransferAccount="setTransferAccountFrom"
          />
        </div>
      </div>

      <div class="transfer-to-client__block">
        <div class="transfer-to-client__selector">
          <TransferSearchAccount label="Номер счета получателя (32 символа)" />
        </div>
      </div>

      <TransferAmountInput
        v-model="amount"
        class="transfer-to-client__amount"
        transfer-type="transfer-to-client"
        :error="transferAmountError"
        :error-message="transferAmountErrorMessage"
      />

      <MainCheckbox
        v-model="isAccepted"
        :model-value="isAccepted"
        title="Совершая перевод я соглашаюсь с его"
        link-text="условиями и тарифами"
        href="/docs/Условия_и_тарифы.docx"
      />

      <div class="transfer-to-client__button">
        <MainButton
          color="gold"
          :disabled="
            !amount ||
            !transferAccountFrom ||
            !transferAccountTo ||
            transferAmountError ||
            !isAccepted
          "
          :title="transferButtonText"
          @click="storeTransferRequest"
        />
      </div>

      <p v-if="transferStatus === 'error'" class="transfer-to-client__error">
        Что-то пошло не так, попробуйте снова
      </p>
    </div>

    <TransferSuccess
      v-if="transferStatus === 'success'"
      type="clients"
      :amount="amount"
      :transfer-account-from="transferAccountFrom"
      :transfer-account-to="transferAccountTo"
    />
  </div>
</template>

<style lang="scss">
.transfer-to-client {
  padding: $space-xxl $space-xxl $space-xxxl;
  background-color: $background-white;

  @media (max-width: 768px) {
    padding: $space-xxl $space-l 0;
  }

  @media (max-width: 375px) {
    padding: $space-xxl $space-m 0;
  }

  &__block {
    margin: 0 0 $space-xl;
  }

  .main-checkbox {
    margin-bottom: $space-l;

    input {
      & + label {
        @include caption-1;
      }
    }
  }

  &__amount {
    margin-bottom: $space-xl;
  }

  &__button {
    width: 300px;

    @media (max-width: 375px) {
      width: 100%;
    }

    @media (max-width: 1023px) {
      height: auto;
      padding: 0 0 $space-xxl;
    }
  }

  &__error {
    @include text-2;
    color: $red;
    margin-top: $space-m;
  }
}
</style>
